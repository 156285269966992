import styled from 'styled-components';
import { media, glsp } from '@devseed-ui/theme-provider';

import UniversalGridder from './universal-gridder';
import { stripe } from './motifs';

const MasonryItemsWrapperAttrs = (props) => ({
  as: 'div',
  grid: props.grid || {
    smallUp: ['full-start', 'full-end'],
    mediumUp: ['full-start', 'full-end'],
    largeUp: ['full-start', 'full-end']
  }
});

export const MasonryItemsWrapper = styled(UniversalGridder).attrs(
  MasonryItemsWrapperAttrs
)`
  position: relative;
  z-index: 2;
  padding-bottom: ${glsp(4)};
  margin-bottom: ${glsp(-4)};

  > * {
    grid-row: 1;
    z-index: 3;
  }

  &::before {
    ${stripe};
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    grid-row: 1;
    grid-column-start: content-3;
    grid-column-end: span full-end;
    top: ${glsp(4)};

    ${media.smallUp`
      grid-column-start: content-3;
      top: ${glsp(8)};
    `}

    ${media.mediumUp`
      grid-column-start: content-3;
      top: ${glsp(4)};
    `}

    ${media.largeUp`
      grid-column-start: content-7;
      top: ${glsp(20)};
    `}
  }

  &::after {
    ${stripe};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    grid-row: 1;
    grid-column-start: full-start;
    grid-column-end: span content-2;
    top: ${glsp(16)};

    ${media.smallUp`
      top: ${glsp(20)};
    `}

    ${media.mediumUp`
      grid-column-end: span content-3;
      top: ${glsp(18)};
    `}

    ${media.largeUp`
      grid-column-end: span content-4;
      top: ${glsp(46)};
    `}
  }
`;

export const MasonryItemsWrapperInner = styled.div`
  grid-column: content-start / content-end;
`;

export const MasonryItemsListElement = styled.ol`
  outline: 0;

  ${media.smallDown`
    > li {
      max-width: 28rem;
      left: 50% !important;
      transform: translate(-50%);
    }
  `}
`;
