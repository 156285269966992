import React, { useState } from 'react';
import T from 'prop-types';
import { Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import styled, { css } from 'styled-components';
import {
  themeVal,
  media,
  glsp,
  visuallyHidden,
  multiply,
  rgba
} from '@devseed-ui/theme-provider';
import { Heading } from '@devseed-ui/typography';
import { SupHeading } from '../styles/typography/supheading';

import {
  AuthorAvatar,
  AuthorsAvatarsTruncated,
  PostAuthor
} from '../components/author-avatar';

export const Card = styled.article`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  padding: ${glsp(2)};
  box-shadow: 0 24px 64px 0 ${themeVal('color.base-200a')};
  border-radius: ${multiply(themeVal('shape.rounded'), 2)};
  min-height: 28rem;
  overflow: hidden;
  transition: all 0.24s ease-in-out 0s;

  ${media.mediumUp`
    padding: ${glsp(2)};
    min-height: 32rem;
  `}

  ${media.xlargeUp`
    padding: ${glsp(3)};
  `}

  ${renderType}
  ${renderSkin}

  > * {
    position: relative;
    z-index: 3;
    pointer-events: none;
    margin-bottom: ${glsp(2)};

    ${media.xlargeUp`
      margin-bottom: ${glsp(3)};
    `}
  }

  a {
    pointer-events: auto;
  }

  ${PostAuthor} {
    margin-top: auto;
    margin-bottom: 0;
  }
`;

export const CardHeader = styled.header`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${glsp()};

  ${media.mediumUp`
    grid-gap: ${glsp(1.5)};
  `}
`;

export const CardTitle = styled(Heading)`
  grid-column: 1 / -1;
  margin: 0;
  font-size: 1.75rem;
  line-height: 2.25rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;

  ${media.mediumUp`
    font-size: 2rem;
    line-height: 2.5rem;
  `}

  ${media.largeUp`
    font-size: 2.5rem;
    line-height: 3rem;
  `}
`;

export const CardSuptitle = styled(SupHeading).attrs({
  as: 'p',
  variation: 'primary'
})`
  grid-row: 1;
  white-space: nowrap;
  color: inherit;
  line-height: 1;
`;

export const CardSubtitle = styled.p`
  grid-column: 1 / -1;
  font-variation-settings: 'wdth' 96, 'wght' 512;
  font-size: 0.875rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: ${rgba(themeVal('color.base-500'), 0.64)};
  white-space: nowrap;
  overflow: hidden;
  mask-image: linear-gradient(
    to right,
    black calc(100% - ${glsp(3)}),
    transparent 100%
  );

  &::before {
    content: '— ';
  }

  a,
  a:visited,
  a:not([class]),
  a:not([class]):visited,
  > span {
    display: inline-flex;
    color: ${themeVal('color.link')};
    line-height: 1rem;
    vertical-align: top;
  }
`;

export const CardHeaderDetails = styled.dl`
  grid-row: 1;
  grid-column: 2;
  display: flex;
  flex-flow: row nowrap;
  white-space: nowrap;
  font-variation-settings: 'wdth' 96, 'wght' 512;
  font-size: 0.875rem;
  line-height: 1rem;
  text-transform: uppercase;
  max-width: 100%;
  overflow: hidden;
  mask-image: linear-gradient(
    to right,
    black calc(100% - ${glsp(3)}),
    transparent 100%
  );

  dt {
    ${visuallyHidden()}
  }

  dd {
    display: block;

    &:not(:last-child)::after {
      content: ', ';
      margin-right: ${glsp(0.25)};
    }

    a,
    a:visited,
    a:not([class]),
    a:not([class]):visited,
    > span {
      line-height: 1rem;
      vertical-align: top;
      color: ${themeVal('color.link')};
    }
  }
`;

export const CardMedia = styled.figure`
  z-index: 1;
  overflow: hidden;
  order: -1;
  margin-top: ${glsp(-2)};
  margin-left: ${glsp(-2)};
  margin-right: ${glsp(-2)};

  ${media.xlargeUp`
    margin-top: ${glsp(-3)};
    margin-left: ${glsp(-3)};
    margin-right: ${glsp(-3)};
  `}

  img {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
  }
`;

export const CardCover = styled.div`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
  }
`;

export const CardBody = styled.div`
  font-variation-settings: 'wdth' 64, 'wght' 356;
  font-size: 1.25rem;
  line-height: 1.75rem;
`;

export const CardMeta = styled.address``;

export const CardFooterDetails = styled.dl`
  dt {
    ${visuallyHidden()}
  }

  img {
    display: none;
  }
`;

export const CardLink = styled(Link)`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: auto;
  font-size: 0;
  background: transparent;
  margin: 0;

  &:hover {
    opacity: 1;
  }
`;

function renderType({ type }) {
  switch (type) {
    case 'blog-post':
      return css`
        ${CardHeader} {
          grid-template-columns: min-content 1fr;
        }
      `;
    case 'expertise':
    case 'project':
      return css`
        min-height: 24rem;

        ${media.smallUp`
          min-height: 32rem;
        `}

        ${media.mediumUp`
          min-height: 40rem;
        `}

        ${CardMedia} {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          margin: 0;
        }

        ${CardCover} {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;

          &::before {
            opacity: 1;
          }
        }

        ${CardTitle} {
          font-size: 2rem;
          line-height: 2.5rem;

          ${media.mediumUp`
            font-size: 2.5rem;
            line-height: 3rem;
          `}

          ${media.largeUp`
            font-size: 3rem;
            line-height: 3.5rem;
          `}
        }

        ${CardBody} {
          margin-bottom: 0 !important;
        }
      `;
    case 'team':
      return css`
        min-height: auto;

        ${media.mediumUp`
          min-height: auto;
        `}

        ${CardTitle} {
          font-size: 2rem;
          line-height: 2.5rem;

          ${media.mediumUp`
            font-size: 2.5rem;
            line-height: 3rem;
          `}

          ${media.largeUp`
            font-size: 3rem;
            line-height: 3.5rem;
          `}
        }

        ${CardBody} {
          margin-bottom: 0 !important;
        }
      `;
    default:
      return css`
        background: #ffffff;
      `;
  }
}

function renderSkin({ skin }) {
  switch (skin) {
    case 'dark':
      return css`
        color: ${themeVal('color.surface')};
        background: ${themeVal('color.base-500')};

        ${CardCover} {
          &::before {
            background: linear-gradient(
              180deg,
              ${rgba(themeVal('color.base-500'), 1)} 48%,
              ${rgba(themeVal('color.base-500'), 0.16)} 100%
            );
            mix-blend-mode: multiply;
          }
        }

        ${CardSubtitle} {
          color: ${rgba(themeVal('color.surface'), 0.64)};
        }
      `;
    default:
      return css`
        background: ${themeVal('color.surface')};

        ${CardCover} {
          &::before {
            background: linear-gradient(
              180deg,
              ${rgba(themeVal('color.surface'), 1)} 48%,
              ${rgba(themeVal('color.surface'), 0.16)} 100%
            );
          }
        }
      `;
  }
}

// // // // // // // // // // // // // // // // // // // // // // // // // // //
// React components for the different card types.
// // // // // // // // // // // // // // // // // // // // // // // // // // //

const CardEvented = styled(Card)`
  ${({ isStateFocus }) =>
    isStateFocus &&
    css`
      box-shadow: 0 16px 32px 0 ${rgba(themeVal('color.base'), 0.16)};
      transform: translate(0, 0);
    `}
  ${({ isStateOver }) =>
    isStateOver &&
    css`
      transform: translate(0, -0.125rem);
      box-shadow: 0 32px 72px 0 ${rgba(themeVal('color.base'), 0.24)};
    `}
  ${({ isStateActive }) =>
    isStateActive &&
    css`
      box-shadow: 0 16px 32px 0 ${rgba(themeVal('color.base'), 0.16)};
      transform: translate(0, 0);
    `}
`;

/**
 * Adds events to the card link that add properties to the card wrapper. Because
 * there may be links in the card, the card itself can't be link. The list
 * itself is positioned on top of the card, so we need it's events (hover,
 * focus, press) to be sent to the parent card to be able to style it
 *
 * @param {Object} props Component properties
 * @param {React node} props.children Elements to render inside the card
 * @param {String} props.linkTo To property for the link
 * @param {String} props.linkTitle Title attribute of the link
 * @param {String} props.linkLabel Label of the link
 */
const CardInteractive = (props) => {
  const { children, linkTo, linkTitle, linkLabel, onClickCapture, ...rest } =
    props;
  const [isStateOver, setStateOver] = useState(false);
  const [isStateActive, setStateActive] = useState(false);
  const [isStateFocus, setStateFocus] = useState(false);

  return (
    <CardEvented
      {...rest}
      onClickCapture={onClickCapture}
      isStateOver={isStateOver}
      isStateActive={isStateActive}
      isStateFocus={isStateFocus}
    >
      {children}
      <CardLink
        to={linkTo}
        title={linkTitle}
        onMouseDown={() => setStateActive(true)}
        onMouseUp={() => setStateActive(false)}
        onMouseEnter={() => setStateOver(true)}
        onMouseLeave={() => {
          setStateOver(false);
          setStateActive(false);
        }}
        onFocus={() => setStateFocus(true)}
        onBlur={() => setStateFocus(false)}
      >
        {linkLabel}
      </CardLink>
    </CardEvented>
  );
};

CardInteractive.propTypes = {
  children: T.node,
  onClickCapture: T.func,
  linkTo: T.string,
  linkTitle: T.string,
  linkLabel: T.string
};

/**
 * Card variation for the Blog
 *
 * @param {Object} props Component properties
 * @param {String} props.title Title for the card
 * @param {String} props.slug Slug of the content. Will be used to construct the
 *                 url
 * @param {String} props.url Absolute url to use. If provided will be used
 *                 instead of the slug
 * @param {number} props.readTime Post read time in minutes
 * @param {Object} props.cardImage Card image properties to be used with Gastby
 *                 Image Fluid variant
 * @param {Array} props.authors Authors of the post
 * @param {Array} props.topics List of topics for this post
 * @param {String} props.date Post date
 * @param {String} props.excerpt Post excerpt
 * @param {number} props.index Index of the post on the list. To be used for the
 *                 zIndex property.
 */
export const CardBlog = ({
  title,
  slug,
  url,
  readTime,
  cardImage,
  authors,
  topics,
  date,
  excerpt,
  index,
  onCardClickCapture,
  className
}) => {
  return (
    <CardInteractive
      className={className}
      style={!isNaN(index) ? { zIndex: index } : undefined}
      type='blog-post'
      onClickCapture={onCardClickCapture}
      linkTo={url ? url : `/blog/${slug}`}
      linkTitle='Visit blog post'
      linkLabel='View more'
    >
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <CardSuptitle>Blog</CardSuptitle>
        <CardSubtitle>{readTime} min read</CardSubtitle>
        {topics && (
          <CardHeaderDetails>
            <dt>Topics</dt>
            {topics.map((t) => (
              <dd key={t}>
                <span>{t}</span>
              </dd>
            ))}
          </CardHeaderDetails>
        )}
      </CardHeader>
      {cardImage && (
        <CardMedia>
          <CardCover>
            <GatsbyImage fluid={cardImage} />
          </CardCover>
        </CardMedia>
      )}
      <CardBody>
        <p dangerouslySetInnerHTML={{ __html: excerpt }} />
      </CardBody>
      {authors.length > 1 ? (
        <AuthorsAvatarsTruncated authors={authors} date={date} />
      ) : authors.length === 1 ? (
        <AuthorAvatar
          name={authors[0].name}
          image={authors[0].avatar}
          date={date}
        />
      ) : (
        <AuthorAvatar date={date} />
      )}
    </CardInteractive>
  );
};

CardBlog.propTypes = {
  className: T.string,
  onCardClickCapture: T.func,
  title: T.string,
  url: T.string,
  slug: T.string,
  readTime: T.number,
  date: T.string,
  excerpt: T.string,
  cardImage: T.object,
  authors: T.arrayOf(
    T.shape({
      slug: T.string,
      name: T.string,
      avatar: T.object
    })
  ),
  topics: T.array,
  index: T.number
};

/**
 * Card variation for the Project
 *
 * @param {Object} props Component properties
 * @param {String} props.title Title for the card
 * @param {String} props.slug Slug of the content. Will be used to construct the
 *                 url
 * @param {Object} props.cardImage Card image properties to be used with Gastby
 *                 Image Fluid variant
 * @param {Object} props.client Client of the project
 * @param {String} props.skin Skin variation for the card. (dark|light)
 * @param {number} props.index Index of the post on the list. To be used for the
 *                 zIndex property.
 */
export const CardProject = ({
  title,
  slug,
  cardImage,
  client,
  index,
  skin,
  onCardClickCapture,
  className
}) => {
  const hasClient = !!(client && client.name);
  const isDevseedClient =
    hasClient && client.name.toLowerCase() === 'development seed';

  return (
    <CardInteractive
      className={className}
      style={!isNaN(index) ? { zIndex: index } : undefined}
      type='project'
      onClickCapture={onCardClickCapture}
      skin={skin}
      linkTo={`/projects/${slug}`}
      linkTitle='Visit project post'
      linkLabel='View more'
    >
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <CardSuptitle>Project</CardSuptitle>
        {hasClient && (
          <CardSubtitle>
            {isDevseedClient ? (
              'By DevSeed Labs'
            ) : (
              <React.Fragment>
                With <span className='client-link'>{client.name}</span>
              </React.Fragment>
            )}
          </CardSubtitle>
        )}
      </CardHeader>
      {cardImage && (
        <CardMedia>
          <CardCover>
            <GatsbyImage fluid={cardImage} style={{ position: 'static' }} />
          </CardCover>
        </CardMedia>
      )}
    </CardInteractive>
  );
};

CardProject.propTypes = {
  className: T.string,
  onCardClickCapture: T.func,
  title: T.string,
  slug: T.string,
  cardImage: T.object,
  client: T.shape({
    name: T.string
  }),
  skin: T.string,
  index: T.number
};

/**
 * Card variation for the Expertise
 *
 * @param {Object} props Component properties
 * @param {String} props.title Title for the card
 * @param {String} props.slug Slug of the content. Will be used to construct the
 *                 url
 * @param {Object} props.cardImage Card image properties to be used with Gastby
 *                 Image Fluid variant
 * @param {String} props.description Description for the body of the card
 * @param {number} props.index Index of the post on the list. To be used for the
 *                 zIndex property.
 */
export const CardExpertise = ({
  title,
  slug,
  cardImage,
  description,
  index,
  onCardClickCapture,
  className,
  skin
}) => {
  return (
    <CardInteractive
      className={className}
      style={!isNaN(index) ? { zIndex: index } : undefined}
      type='expertise'
      onClickCapture={onCardClickCapture}
      linkTo={`/expertise/${slug}`}
      linkTitle='Visit expertise post'
      linkLabel='View more'
      skin={skin ?? 'dark'}
    >
      <CardHeader>
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      {cardImage && (
        <CardMedia>
          <CardCover>
            <GatsbyImage fluid={cardImage} style={{ position: 'static' }} />
          </CardCover>
        </CardMedia>
      )}
      {description && <CardBody>{description}</CardBody>}
    </CardInteractive>
  );
};

CardExpertise.propTypes = {
  className: T.string,
  onCardClickCapture: T.func,
  title: T.string,
  slug: T.string,
  description: T.string,
  cardImage: T.object,
  index: T.number,
  skin: T.string
};

/**
 * Card variation for the Team
 *
 * @param {Object} props Component properties
 * @param {String} props.name Title for the card
 * @param {String} props.slug Slug of the content. Will be used to construct the
 *                 url
 * @param {Object} props.cardImage Card image properties to be used with Gastby
 *                 Image Fluid variant
 * @param {String} props.description Description for the body of the card
 * @param {String} props.expertise The expertise of the team member
 * @param {number} props.index Index of the post on the list. To be used for the
 *                 zIndex property.
 */
export const CardTeam = ({
  name,
  slug,
  cardImage,
  description,
  expertise,
  index,
  onCardClickCapture,
  className
}) => {
  return (
    <CardInteractive
      className={className}
      style={!isNaN(index) ? { zIndex: index } : undefined}
      type='team'
      onClickCapture={onCardClickCapture}
      linkTo={`/team/${slug}`}
      linkTitle='Visit team page'
      linkLabel='View more'
    >
      <CardHeader>
        <CardTitle>{name}</CardTitle>
        <CardSubtitle>{expertise}</CardSubtitle>
      </CardHeader>
      {cardImage && (
        <CardMedia>
          <CardCover>
            <GatsbyImage fluid={cardImage} />
          </CardCover>
        </CardMedia>
      )}
      {description && <CardBody>{description}</CardBody>}
    </CardInteractive>
  );
};

CardTeam.propTypes = {
  className: T.string,
  onCardClickCapture: T.func,
  name: T.string,
  slug: T.string,
  description: T.string,
  cardImage: T.object,
  index: T.number,
  expertise: T.string
};
